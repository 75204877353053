export default function getAssetFormat(dimensions) {
  if (!dimensions) return null

  // Calculate asset format based on asset ratio
  const ratio = dimensions.width / dimensions.height
  let format = "square"
  if (ratio > 1.1) {
    format = "landscape"
  } else if (ratio < 0.9) {
    format = "portrait"
  }
  return format
}
